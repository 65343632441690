@import '../theme-bootstrap';

// Engraving Fonts

// Bernhard Modern Std - Bold
@font-face {
  font-family: 'Bernhard Modern Bold';
  src: local('Bernhard Modern Bold'), local('Bernhard-Modern-Bold'),
    url('#{$fonts-path}BernhardModern/BernhardModernStd-Bold.woff2') format('woff2');
  font-display: swap;
}

// Kuenstler Script - Regular
@font-face {
  font-family: 'Kuenstler Script';
  src: local('Kuenstler Script'), local('Kuenstler-Script'),
    url('#{$fonts-path}KuenstlerScript/KuenstlerScriptLTStd-Medium.woff2') format('woff2');
  font-display: swap;
}
